<template>
  <section id="drafts">
    <v-card class="custom-border border pa-2">
      <section class="d-flex flex-row align-center">
        <v-card-title>
          <h4 class="poppins secondary-1--text fw600">Drafts</h4>
        </v-card-title>
        <v-spacer />
        <div class="d-flex justify-end">
          <v-btn 
            text 
            small
            @click="gallery =  true"
            class="hidden-sm-and-down"
          >
            <v-icon color="" :class="gallery ? 'primary--text' : 'secondary-2--text'">
              mdi-view-gallery-outline
            </v-icon>
          </v-btn>
          <v-btn 
            text 
            small
            @click="gallery = false"
            class="hidden-sm-and-down"
          >
            <v-icon color="" :class="!gallery ? 'primary--text' : 'secondary-2--text'">
              mdi-format-list-bulleted
            </v-icon>
          </v-btn>
        </div>
      </section>
      <section class="mx-3">
        <SearchBar :placeholder="'Search course'" :outlined="true" :value.sync="search" @search="() => { page = 1, getDraftedCourses() }"/>
      </section>

      <v-progress-linear
          v-if="loading"
              indeterminate
              color="primary"
              rounded
              height="4"
              class="my-3"
      ></v-progress-linear>


      <v-card-text v-if="courses.length>0" class="d-flex" :class="[$vuetify.breakpoint.mobile && 'justify-center', gallery ? 'flex-wrap' : 'flex-column']">
        <CourseCardGallery 
          v-if="gallery"
          v-for="(item,i) in courses" 
          :key="item.id"
          :i="i" 
          :item="item" 
          :gallery="gallery"
          class="ma-3"
        />
        <CourseCardList 
          v-if="!gallery"
          v-for="(item,i) in courses" 
          :key="item.id"
          :i="i" 
          :item="item" 
          class="ma-3"
        />
        
      </v-card-text>
      <v-sheet
        color="transparent"
        class="d-flex flex-column align-center justify-center pb-10"
        height="500"
        v-if="courses.length === 0 && !loading"
      >
        <div class="">
          <v-img 
            max-width="300" 
            :src="require('../../assets/default/empty_drafts.png')"/>
        </div>
        <h3 class="poppins secondary-1--text fw600 my-5">Nothing in drafts</h3>
      </v-sheet>
                        
      <FormPagination 
        :pageCount="pageCount" 
        :page="page"
        :paginate="paginate"
        @page="(e) => {page = e, getDraftedCourses()}" 
        @paginate="(e) => {paginate = e, getDraftedCourses()}"/>
      
    </v-card>
    
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CourseCardGallery from "@/components/teacher/course/CourseCardGallery.vue";
import CourseCardList from "@/components/teacher/course/CourseCardList.vue";
export default {
  components: {
    CourseCardGallery,
    CourseCardList,
  },
  data: () => ({
    loading: false,
    gallery: true,
    pageCount: 1,
    paginate: '10',
    page: 1,
    search: ''
  }),
  computed: {
    ...mapState("instructor", {
      courses: (state) => state.courses,
    }),
  },
  mounted() {
    window.addEventListener('resize', this.onResize, {passive: true})
    this.onResize()
    this.getDraftedCourses()
  },
  methods: {
    ...mapActions("instructor", ["getDraftsCoursesAction"]),

    onResize(){
      if(this.$vuetify.breakpoint.smAndDown || this.$vuetify.breakpoint.mobile){
        this.gallery = false
      }
    },

    getDraftedCourses() {
      this.loading = true
      this.getDraftsCoursesAction({ paginate: Number(this.paginate), page: this.page, search: this.search}).then(res => {
        this.page = res.current_page
        this.pageCount = res.last_page
        this.paginate = String(res.per_page)
      }).finally(() => {
        this.loading = false;
      });
    }
  },
};
</script>
